@import "variabls";
@import "custom-variables";
@import "common";
@import "header/header";
@import "hero/hero-slider";
@import "component/button";
@import "component/history";
@import "component/service";
@import "component/funfact-counter";
@import "component/project-tab";
@import "component/client";
@import "component/brand-logo";
@import "component/news";
@import "component/team";
@import "component/mobile-menu";
@import "component/breadcrumb";
@import "pages/project";
@import "pages/blog";
@import "pages/about";
@import "pages/contact";
@import "footer/footer";