/*----------------------------------------*/
/*  05. Pages CSS
/*----------------------------------------*/
/*====================  
    5.1 Project Page CSS
====================*/
.maxh-full {
  height: calc(100vh - 130px);
  @media #{$large-mobile} {
    height: calc(100vh - 97px);
  }
  @media #{$extra-small-mobile} {
    height: calc(100vh - 78px);
  }
}
.maxh-70vh {
  max-height: 70vh;
}
.maxh-300 {
  max-height: 300px;
}
.project-masonry-section {

    & .messonry-button{
        margin-bottom: 65px;
        line-height: 1;
        text-align: center;

        & .port-filter {
            display: inline-block;
            margin: 0px 20px;
            font-size: 16px;
            line-height: 1;
            font-weight: 600;
            color: $dark;
            z-index: 1;
            cursor: pointer;
            text-transform: uppercase;
            transition: $transition-base;

            &:hover, &.is-checked {
                color: $primary;
            }

            @media #{$tablet-device, $large-mobile} {
                margin: 0 10px 10px; 
            }
            @media #{$small-mobile} {
                margin: 0 5px 15px; 
            }
        }
    }

    & .mesonry-list {
        margin: 0 -45px;

        @media #{$laptop-device, 
            $desktop-device, 
            $tablet-device, 
            $large-mobile} {
            margin: 0 -15px;
        }
    }
}
.single-project-wrap {
    padding: 0 30px;

    @media #{$laptop-device, 
        $desktop-device, 
        $tablet-device, 
        $large-mobile} {
        padding: 0 5px;
    }

    & .image {
        display: block;
        overflow: hidden;
        & img {
            width: 100%;
            transition: $transition-base;
        }
    }

    &:hover {
        & .image {
            & img {
                transform: scale(1.1) rotate(1deg);
                transition: $transition-base;
            }
        }
    }

    & .inner-content {
        // padding: 25px 0 20px;
        @media #{$tablet-device, $large-mobile} {
            padding-bottom: 0px;
        }
        & .sub-title {
            font-size: 14px;
            font-weight: 400;
            font-family: $font-family-base;
            color: $gray-300;
            line-height: 1;
        }

        & .title {
            font-family: $font-family-base;
            font-weight: 600;
            font-size: 1em;
            color: $dark;
            margin-top: 10px;
            line-height: 1.3;

            & a {
                &:hover {
                    color: $primary;
                }
            }

            @media #{$small-mobile} {
                font-size: 20px;
                line-height: 1;
            }
        }
    }
}
.load-more{
    text-align:center;

    & a{
        color:$dark;
        font-size:16px;
        font-weight:600;
        letter-spacing:7px;
        text-transform:uppercase;
        transition: $transition-base;
        line-height: 1;
        display: block;

        &:hover{
            color: $primary;
        }

        @media #{$small-mobile} {
            font-size: 14px;
            letter-spacing:4px;
        }
    }
}

/*-- Project Details Style --*/
.project-details-content {
    & .project-details-title {
        font-size: 48px;
        color: $dark;
        font-family: $font-family-base;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 20px;

        @media #{$tablet-device} {
            font-size: 35px;
        }
        @media #{$large-mobile} {
            font-size: 30px;
        }

    }

    & p {
        font-size: 1em;
        font-weight: 400;
        color: $gray-300;
        margin-bottom: 35px;
        @media #{$tablet-device} {
            margin-bottom: 20px;
        }
        @media #{$large-mobile} {
            margin-bottom: 20px;
        }
    }
}

.info-boxed{
	padding:80px 90px;
    background-color: $gray-100;

    @media #{$desktop-device} {
        padding: 80px 70px;
    }
    @media #{$large-mobile} {
        padding: 40px;
    }
    @media #{$extra-small-mobile} {
        padding: 20px;
    }

    & ul{
        & li{
            color: $gray-300;
            font-size: 14px;
            line-height: 1.3;
            margin-bottom: 21px;

            & span{
                color: $dark;
                width: 200px;
                display: inline-block;
                font-weight: 600;
                text-transform: uppercase;

                @media #{$large-mobile} {
                    width: 150px;
                }
                @media #{$small-mobile} {
                    width: 110px;
                }
                @media #{$extra-small-mobile} {
                    width: 100%;
                    margin-bottom: 10px;
                }

            }
            &:last-child{
                margin-bottom: 0px;
            }
        }
    }
}

/*-- Project Details Crousel --*/
.project-details-carousel {
    margin-left: -170px;
    margin-right: -170px;
    position: relative;
    @media #{$laptop-device, $desktop-device, $tablet-device, $large-mobile} {
        margin-left: 0;
        margin-right: 0;
    }
    & .project-details-slider-prev, & .project-details-slider-next {
        display: flex;
        align-items: center;
        
        width: 40px;
        height: 40px;
        transition: $transition-base;

        &:focus{
            border: 0px;
            outline: 0px;
        }

        &:after{
            display: none;
        }

        & i{ 
            font-size: 26px;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            width: 40px;
            height: 40px;
            background: $dark;
            &:hover{
                color: $white;
                background: $primary !important;
                transition: .3s;
            }

            @media #{$small-mobile} {
                width: 30px;
                height: 30px;
                font-size: 20px;
            }
        }
    }
    & .project-details-slider-prev {
        left: 70px;
        transition: $transition-base;
        @media #{$desktop-device, $tablet-device} {
            left: 40px;
        }

        @media #{$large-mobile} {
            left: 20px;
        }
    }
    & .project-details-slider-next {
        right: 70px;
        transition: $transition-base;
        @media #{$desktop-device, $tablet-device} {
            right: 40px;
        }

        @media #{$large-mobile} {
            right: 20px;
        }
    }
    .swiper-pagination {
        position: absolute;
        margin-top: 0px;
        margin-bottom: 30px;
        bottom: 0;

        @media #{$small-mobile} {
            margin-bottom: 10px;
            bottom: 0;
        }
        .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
      
            &:hover{
                &:before{
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }
}

/*-- Project Left & Right Content Box --*/
.project-right-content-box {
    & .project-box-image {
        margin-left: 200px;

        @media #{$desktop-device} {
            margin-left: 120px;
        }
        @media #{$tablet-device, $large-mobile} {
            margin-left: 0;
        }
    }

    & .project-box-inner {
        padding: 0 100px 0 70px;
        position: relative;

        @media #{$desktop-device} {
            padding: 0 40px 0 40px;
        }

        @media #{$tablet-device, $large-mobile} {
            padding: 0 0 0 40px; 
        }

        & .section-title {
            margin-bottom: 50px;

            @media #{$desktop-device} {
                margin-bottom: 30px;
            }
            @media #{$tablet-device} {
                margin-bottom: 30px;
            }
            @media #{$large-mobile} {
                margin-bottom: 25px;
            }
        }

        & p {
            font-size: 14px;
            color: $gray-300;
        }

        &::after {
            position: absolute;
            content: "";
            background: $border-color;
            width: 1px;
            height: 130px;
            top: 0;
            right: 0;

            @media #{$tablet-device, $large-mobile} {
                left: 0;
                right: auto;
            }
        }
    }
}
.project-left-content-box {

    & .project-box-inner {
        padding-left: 200px;
        padding-right: 70px;
        position: relative;

        @media #{$desktop-device} {
            padding-left: 140px;
            padding-right: 25px;
        }

        @media #{$tablet-device, $large-mobile} {
            padding: 0px;
        }

        & .section-title {
            margin-bottom: 50px;

            @media #{$desktop-device} {
                margin-bottom: 30px;
            }
            @media #{$tablet-device} {
                padding-top: 60px;
                margin-bottom: 30px;
            }
            @media #{$large-mobile} {
                padding-top: 40px;
                margin-bottom: 25px;
            }
        }

        & p {
            font-size: 14px;
            color: $gray-300;
        }

        &::after {
            position: absolute;
            content: "";
            background: $border-color;
            width: 115px;
            height: 1px;
            top: 0;
            left: 0;
        }
    }
}
.blockquote {
    padding-left: 100px;
    position: relative;
    margin-bottom: 0;
    @media #{$small-mobile} {
        padding-left: 30px;
    }

    & p {
        font-size: 24px;
        font-weight: 400;
        color: $dark;
        line-height: 1.5;

        @media #{$desktop-device, $tablet-device} {
            font-size: 20px;
        }
        @media #{$large-mobile} {
            font-size: 18px;
        }
    }

    &::after {
        position: absolute;
        content: "";
        width: 2px;
        height: 130px;
        background: $primary;
        left: 0;
        top: 0;
    }

    & .blockquote-footer {
        margin-top: 15px;
        & .title-name {
            font-weight: 600;
            font-size: 18px;
            text-transform: capitalize;
            color: $dark;
        }
        & .title-desig {
            font-weight: 400;
            font-size: 16px;
            text-transform: uppercase;
            color: $gray-300;
        }
    }
}
/*-- New Project Section Style --*/
.new-project-section {
    padding: 80px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media #{$tablet-device} {
        padding: 60px 0;
    }
    @media #{$large-mobile} {
        padding: 40px 0;
    }
    @media #{$extra-small-mobile} {
        justify-content: center;
    }

    a {
        color: $gray-600;
        font-size: 18px;
        font-weight: 600;

        &:hover {
            color: $dark;
        }

        @media #{$large-mobile} {
            font-size: 16px;
        }
    }
}