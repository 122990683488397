/*====================  
    4.5 Project Tab CSS
====================*/
.section-tabs-header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    @media #{$desktop-device, $tablet-device} {
        margin-top: 20px;
     }
     @media #{$large-mobile} {
        margin-bottom: 30px;
        margin-top: 20px;
    }
}
.tabs-header-nav {
    & .nav-item {
        & .nav-link {
            position: relative;

            padding: 15px 0 5px 0;
            line-height: 1;
            margin-right: 60px;

            color: $gray-400;
            text-transform: uppercase;

            font-family: $font-family-base;
            font-weight: 600;
            font-size: 16px;

            @media #{$tablet-device, $large-mobile} {
                margin-right: 30px;
            }
            @media #{$extra-small-mobile} {
                padding-top: 25px;
            }

            &::before {
                position: absolute;
                content: "";
                background: $dark;
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
                transition: $transition-base;
            }

            &.active, &:hover {
                color: $dark;
                &::before {
                    width: 100%;
                    transition: $transition-base;
                }
            }
        }
        &:last-child {
            & .nav-link {
                margin-right: 0;
            }
        }
    }
}
.all-project-btn {
    line-height: 1;
    padding: 15px 0 5px 0;
    text-transform: uppercase;
    text-align: end;
    margin-bottom: 50px;

    @media #{$large-mobile} {
        text-align: start;
    }
    @media #{$desktop-device, $tablet-device} {
        margin-top: 20px;
    }

    & a {
        font-size: 16px;
        font-weight: 600;
        font-family: $font-family-base;
        color: $dark;
        &:hover {
            color: $primary;
        }
    }
}
.single-project-slide {
    position: relative;

    & .content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 30px 60px;

        @media #{$laptop-device, 
            $desktop-device, 
            $tablet-device, 
            $large-mobile} {
            padding: 30px 30px;
         }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 0;
            background: $white;
            left: 0;
            bottom: 0;
            transition: .6s;
        }

        & .subtitle {
            font-size: 14px;
            font-weight: 400;
            color: $white;
            font-family: $font-family-base;
        }

        & .title {
            font-size: 34px;
            font-weight: 600;
            font-family: $font-family-base;
            @media #{$large-mobile} {
                font-size: 22px;
             }

            & a {
                color: $white;
            }
        }
    }

    &:hover {
        & .content {
            &::before {
                height: 100%;
                transition: .6s;
            }

            & .subtitle {
                color: $dark;
                position: relative;
                z-index: 9;
            }

            & .title {
                position: relative;
                z-index: 9;
                & a {
                    color: $primary;
                }
            }
        }
    }
}
/*-- Tab Pane Carousel Style --*/
.tab-pane-carousel, .tab-pane-carousel-two {
    position: relative;
    & .tab-carousel-prev, & .tab-carousel-next {
        display: flex;
        align-items: center;
        
        width: 40px;
        height: 40px;
        transition: $transition-base;

        &:focus{
            border: 0px;
            outline: 0px;
        }
        &:after{
            display: none;
        }
        & i{ 
            opacity: 0;
            visibility: hidden;
            font-size: 30px;
            color: $dark;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            width: 40px;
            height: 40px;
            &:hover{
                color: $white;
                background: $primary !important;
                transition: .3s;
            }
        }
    }
    &:hover {
        & .tab-carousel-prev, & .tab-carousel-next{
            & i{
                opacity: 1;
                visibility: visible;
                background: $gray-100;
                transition: $transition-base;
            }
        }
        & .tab-carousel-prev {
            left: 70px;
            transition: $transition-base;
            @media #{$desktop-device, $tablet-device} {
                left: 40px;
             }
            @media #{$large-mobile} {
                left: 20px;
             }
        }
        & .tab-carousel-next {
            right: 70px;
            transition: $transition-base;
            @media #{$desktop-device, $tablet-device} {
                right: 40px;
             }
            @media #{$large-mobile} {
                right: 20px;
             }
        }
    }
}