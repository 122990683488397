/*====================  
    4.4 Funfact Counter CSS
====================*/
.funfact-inner-container {
    margin-top: 100px;
    padding-top: 100px;
    border-top: 1px solid #bfbfbf;

    @media #{$tablet-device} {
        margin-top: 80px;
        padding-top: 80px;
     }
     @media #{$large-mobile} {
        margin-top: 60px;
        padding-top: 60px;
     }
}
.single-funfact {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @media #{$tablet-device, $large-mobile} {
        justify-content: center;
        text-align: center;
    }

    @media #{$small-mobile} {
        flex-direction: column;
    }

    & .odometer {
        font-size: 50px;
        font-weight: 600;
        font-family: $font-family-base;
        color: $dark;
        line-height: 1;

        @media #{$large-mobile} {
            font-size: 40px;
        }
    }

    & .title {
        font-size: 16px;
        font-weight: 600;
        color: #999999;
        font-family: $font-family-base;
        text-transform: uppercase;
        padding-left: 20px;

        @media #{$tablet-device} {
            padding-left: 0;
            margin-top: 15px;
        }
        @media #{$large-mobile} {
            font-size: 14px;
            padding-left: 0;
            margin-top: 15px;
            line-height: 1.4;
        }
    }
}